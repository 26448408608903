@use "../../../src/styles/abstracts/variables" as *;
@use "../../../src/styles/abstracts/mixins" as *;

.layout {
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
  width: 100vw;
  width: -moz-available;
  width: -webkit-fill-available;
  background-color: $col-green;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  z-index: 100000000;
  position: fixed;

  @include flex-column;
  gap: 1rem;

  &__hidden {
    overflow: hidden;
    @include flex-column;
  }
}

@use "../../../src/styles/abstracts/variables" as *;
@use "../../../src/styles/abstracts/mixins" as *;

.hero {
  width: 100%;
  z-index: 100;
  background: $col-white;
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include respond(tab) {
    height: auto;
  }

  &__container {
    width: 100%;
    max-width: $max-md;
    margin: auto;
    padding: 4rem 1.5rem 5rem;
    font-size: $font-body;
    gap: 4rem;

    @include flex-space-between;

    @include helvetica-light;

    @include respond(tab) {
      padding: 0rem 1.5rem;
      margin: 5rem auto;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__left {
    @include column;
    width: 45%;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    z-index: 2;

    @include respond(tab) {
      width: 100%;
      align-items: center;
    }

    &__btnCon {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      gap: 1rem;
      flex-wrap: wrap;

      @include respond(tab) {
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
    }

    &__button {
      & button {
        & span {
          font-size: $font-body;
        }
      }
    }

    &__top {
      @include column;

      &__text {
        font-size: $font-hero;
        white-space: nowrap;

        @include libre-regular;

        @include respond(tab) {
          text-align: center;
        }
      }

      &__hidden {
        overflow: hidden;
      }
    }

    &__bottom {
      @include column;

      &__text {
        white-space: nowrap;
        font-size: $font-sub;
        // visibility: hidden;

        @include helvetica-light;

        @include respond(tab) {
          font-size: $font-sub-2;
          text-align: center;
        }
      }

      &__hidden {
        overflow: hidden;
      }
    }
  }

  &__right {
    position: relative;
    width: fit-content;
    max-width: 45%;

    @include flex-row;

    @include respond(tab) {
      width: fit-content;
      max-width: 100%;
      margin: auto;
    }

    @include respond(phone) {
      margin: 0 0 0 1rem;
    }

    &__orange {
      background-color: $col-orange;
      width: 100%;
      height: 99%;
      position: absolute;
      transform: translate(-1rem, 1rem);
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);

      visibility: hidden;
    }

    &__image {
      position: relative;
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
      visibility: hidden;
    }
  }

  &__whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;

    @include respond(phone) {
      bottom: 10px;
      right: 10px;
    }
  }
}

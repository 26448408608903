@use "../../../src/styles/abstracts/variables" as *;
@use "../../../src/styles/abstracts/mixins" as *;

.website {
  width: 100%;
  background-color: $col-green;
  margin-top: -0.5rem;

  &__container {
    width: 100%;
    max-width: $max-md;
    margin: 5rem auto;
    padding: 0 1.5rem;
    font-size: $font-body;
    gap: 2.5rem;

    @include column;
    align-items: center;
    justify-content: center;

    @include helvetica-light;
  }

  &__text {
    @include libre-regular;
    text-align: center;
    color: $col-white;
    font-size: $font-guest-site-header;
    max-width: 60rem;
    overflow: hidden;

    &__tiny {
      text-align: center;
      color: $col-white;
      font-size: $font-body;
      max-width: 42.5rem;
      overflow: hidden;

      @include helvetica-light;

      @include respond(tab) {
        font-size: $font-sub;
        max-width: 33.5rem;
      }

      & .text-line {
        overflow: hidden;
      }
    }
  }

  &__hidden {
    overflow: hidden;
  }

  &__image {
    border-radius: 0.625rem;
    position: relative;
  }

  &__button {
    & button {
      font-size: $font-body;

      @include respond(tab) {
        font-size: $font-sub;
      }
    }
  }
}

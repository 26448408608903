@use "../../../src/styles/abstracts/variables" as *;
@use "../../../src/styles/abstracts/mixins" as *;

.photo {
  height: 100vh;
  width: 100vw;
  position: sticky;
  bottom: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: -1;

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

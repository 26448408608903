@use "../../../src/styles/abstracts/variables" as *;
@use "../../../src/styles/abstracts/mixins" as *;

.pictures {
  width: 100%;
  background-color: $col-green;

  &__container {
    width: 100%;
    max-width: $max-md;
    margin: 5rem auto 8rem auto;
    padding: 0 1.5rem;
    font-size: $font-body;
    gap: 2.5rem;

    @include column;
    align-items: center;
    justify-content: center;

    @include helvetica-light;
  }

  &__text {
    @include libre-regular;
    text-align: center;
    color: $col-white;
    font-size: $font-section-header;

    &__tiny {
      text-align: center;
      color: $col-green-3;
      font-size: $font-body;

      @include helvetica-light;
    }
  }

  &__hidden {
    overflow: hidden;
  }

  &__collection {
    position: relative;
    width: 100%;
    height: min(80vw, 42rem);

    &__item {
      overflow: hidden;
      position: absolute;
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
      visibility: hidden;

      &_1 {
        z-index: 1;
        top: 0;
        left: 0.5%;
        width: min(38%, 362px);
      }

      &_2 {
        z-index: 5;
        bottom: -7%;
        left: 7.5%;
        width: min(38%, 362px);
      }

      &_3 {
        z-index: 4;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: min(38%, 338px);
      }

      &_4 {
        z-index: 2;
        top: 0;
        right: -1%;
        width: min(40%, 400px);
      }

      &_5 {
        z-index: 3;
        bottom: -7%;
        right: 7.5%;
        width: min(40%, 400px);
      }
    }
  }
}

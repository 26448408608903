@use "../../../../src/styles/abstracts/variables" as *;
@use "../../../../src/styles/abstracts/mixins" as *;

.bottom {
  width: 100%;
  background-color: $col-orange-3;
  color: $col-black;

  &__container {
    width: 100%;
    max-width: $max-md;
    margin: 0 auto;
    padding: 7rem 0;
    font-size: $font-body;
    gap: 7rem;
    position: relative;

    @include column;
    align-items: center;
    justify-content: center;

    @include helvetica-light;
  }

  &__hidden {
    overflow: hidden;

    @include respond(tab) {
      width: 100%;
    }
  }

  &__heading {
    font-size: $font-section-header;
    @include libre-regular;

    @include respond(tab) {
      font-size: $font-section-header-2;
      text-align: center;
    }
  }

  &__text {
    font-size: $font-section-body;
    @include helvetica-light;
    max-width: 20rem;

    @include respond(tab) {
      margin: 0 auto;
      font-size: $font-section-body-2;
      text-align: center;
    }
  }

  &__first {
    position: relative;

    &__left {
      @include column;
      align-items: flex-start;
      justify-content: center;
      gap: 1.5rem;
      max-width: 27rem;
      width: 47.5%;

      @include respond(tab) {
        align-items: flex-start;
        width: 100%;
      }
    }

    &__right {
      position: relative;
      width: 47.5%;

      @include respond(tab) {
        width: 100%;
      }

      &__orange {
        width: 100%;
        height: 99%;
        position: absolute;
        transform: translate(-1rem, 1rem);
        z-index: 1;
      }

      &__under {
        width: 100%;
        z-index: 2;
      }

      &__on-top {
        z-index: 3;
        position: absolute;
        bottom: 0;
        transform: translate(-4.5rem, 4.5rem);

        @include respond(phone) {
          transform: translate(-2.5rem, 4.5rem);
        }
      }
    }
  }

  &__second {
    position: relative;

    &__left {
      @include column;
      align-items: flex-start;
      justify-content: center;
      gap: 1.5rem;
      max-width: 25rem;
      width: 47.5%;

      @include respond(tab) {
        align-items: flex-start;
        width: 100%;
      }
    }

    &__right {
      @include column;
      align-items: center;
      justify-content: center;
      width: 47.5%;

      @include respond(tab) {
        width: 100%;
      }

      &__under {
        width: 100%;
        z-index: 2;
      }

      &__on-top {
        z-index: 1;
        position: absolute;
        bottom: 0;
        transform: translate(-13.5rem, -3.5rem);

        @include respond(phone) {
          transform: translate(-4rem, -2.5rem);
        }
      }
    }
  }
}

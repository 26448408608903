@use "../../../../src/styles/abstracts/variables" as *;
@use "../../../../src/styles/abstracts/mixins" as *;

.singleSection {
  width: 100%;
  padding: 0 1.5rem;
  @include flex-space-between;
  max-width: 1100px;
  gap: 4rem;

  @include respond(tab) {
    @include column;
    justify-content: flex-start;
    align-items: center;
  }
}

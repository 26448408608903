@use "../../../../src/styles/abstracts/variables" as *;
@use "../../../../src/styles/abstracts/mixins" as *;

.top {
  width: 100%;
  background-color: $col-white;

  &__container {
    width: 100%;
    max-width: $max-md;
    margin: 0 auto;
    padding: 7rem 0;
    font-size: $font-body;
    gap: 5rem;
    position: relative;

    @include column;
    align-items: center;
    justify-content: center;

    @include helvetica-light;
  }

  &__hidden {
    overflow: hidden;

    @include respond(tab) {
      width: 100%;
    }
  }

  &__heading {
    font-size: $font-section-header;
    @include libre-regular;

    @include respond(tab) {
      font-size: $font-section-header-2;
      text-align: center;
    }
  }

  &__text {
    font-size: $font-section-body;
    @include helvetica-light;

    @include respond(tab) {
      font-size: $font-section-body-2;
      margin: 0 auto;
      text-align: center;
    }
  }

  &__first {
    position: relative;

    &__left {
      position: relative;
      width: 47.5%;

      @include respond(tab) {
        width: 100%;
      }

      &__under {
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
      }

      &__on-top {
        z-index: 2;
        position: absolute;
        right: 10%;
        top: 50%;
        height: 80%;
        transform: translate(5rem, -13.5rem);

        @include respond(phone) {
          top: 12rem;
          right: 0;
          transform: translate(1rem, -6rem);
        }
      }
    }

    &__right {
      @include column;
      align-items: flex-start;
      justify-content: center;
      gap: 1.5rem;
      max-width: 27rem;
      width: 47.5%;

      @include respond(tab) {
        align-items: flex-start;
        width: 100%;
      }
    }
  }

  &__second {
    position: relative;

    &__left {
      position: relative;
      width: 47.5%;

      @include respond(tab) {
        width: 100%;
      }

      &__on-top {
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
      }
    }

    &__right {
      @include column;
      align-items: flex-start;
      justify-content: center;
      gap: 1.5rem;
      max-width: 25rem;
      width: 47.5%;

      @include respond(tab) {
        align-items: flex-start;
        width: 100%;
      }
    }
  }
}
